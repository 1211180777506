.cl_Container {
    position: absolute;
    margin: 0;
    max-width: 75%;
    padding: 0 0 0 0;
}

.cl_AddUserButton {
    border-radius: 5px !important;
    display: inline-block !important;
    margin-bottom: 5px;
    position: relative;
    vertical-align: middle !important;
}

.cl_AddUserPWButtons {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 5px !important;
    position: relative !important;
    float: right;
    max-width: 100px !important;
    max-height: 50px !important;
}