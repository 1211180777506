#isp-navi nav.navbar div.container {
    display: block;
    padding: 0px;
    font-size: 20px;
}

.navbar-nav a span {
    vertical-align: sub;
}

.navbar-nav a:hover {
    color: #009BDC;
    text-decoration: none;
    font-weight: bold;
}

.navbar-expand .navbar-nav {
    flex-direction: column;
}

.navbar-nav div.nav-active a{
    color: #009BDC;
    font-weight: bold;
}

.navbar-nav .sub-item {
    font-size: smaller;
    margin-left: 0.2em;
    line-height: 1px;
}

#isp-navi {
    background-color: rgb(239 235 235 / 60%);
    box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
    -webkit-box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
    -moz-box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
}