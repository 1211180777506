.cl_header {
    position: fixed;
    left: 0;
    top: 0;
    height: 100px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 2em;
}

.cl_headerText {
    color: black;
    text-decoration: none !important;
    vertical-align: middle;
    flex: 1;
    margin-top: 9px;
}

.cl_header_img {
    max-height: 82px;
    height: 82px;
    width: auto;
    margin-top: 9px;
}

.cl_headerColLeft {
    flex: 1;
    min-width: 50%;
    max-width: 50%;
    max-height: 100px;
    float: left;
}
.cl_headerColRight{
    flex: 1;
    max-width: 50%;
    min-width: 50%;
    max-height: 100px;
    float: right;
}

.cl_header_button {
    width: 100px;
    max-width: 100px;
    height: 30px;
    max-height: 30px;
    border-radius: 0;
    border: #702673;
    display: inline-block;
    background-color: #702673;
    border-color: #702673 !important;
    color: #fff;
    text-align: center;
    font-size: small;
    margin-left: auto;
    margin-top: 9px;
}

.cl_header_button:hover {
    background-color: #702673;
    border-color: #702673;
    color: rgb(201, 201, 201)
}

button:disabled,
button[disabled] {
    border: 1px solid #999999 !important;
    background-color: #5e5e5e !important;
    color: #ffffff !important;
}

.margin-8 {
    margin: 0.5em;
}

.no-padding {
    padding: 0em;
}

header.ispHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 5;
    padding-bottom: 0.5em;
    box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
    -webkit-box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
    -moz-box-shadow: 2px 2px 8px -2px rgba(51,51,51,0.75);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #61dafb;
  }

/* User Info in Header */
#header-user-info > p {
    width: fit-content;
    white-space: nowrap;
    margin: 0;
    padding: 0.1rem 0 0 0;
}