.admin-content {
    margin-top: 0.5em;
}

div.admin-headline {
    height: 2.0em;
    border-bottom: 1px solid black;
    margin-bottom: 2.3rem;
    width: 100%;
}

.admin-headline h4 {
    width: fit-content;
    margin: auto;
    text-align: center;
}

#user-content-container .row > .col > a {
    display: block;
    width: 90%;
}

#menu-options .row > .col {
    width: 50%;
    display: flex;
}

#menu-options .row > .col:first-child {
    justify-content: start;
}

#menu-options .row > .col:last-child {
    justify-content: end;
}

.dwbo-card {
    width: 100%;
    height: 10rem;
    text-align: center;
    border-color: #009BDC;
    -webkit-box-shadow: 3px 4px 7px -6px #009BDC;
    box-shadow: 3px 4px 7px -6px #009BDC;
}

.dwbo-card .card-header {
    background-color: #009BDC;
    color: white;
}

a.card-link:hover {
    font-weight: bolder;
    color: #009BDC;
}

a.card-link:hover .dwbo-card {
    -webkit-box-shadow: 0px 0px 7px 1px #009bdc;
    box-shadow: 0px 0px 7px 1px #009bdc;
}

.list-container {
    margin-top: 3.25em;
}

.list-container .new-object-creation {
    margin-bottom: 1em;
    line-height: 2em;
    height: 2em;
}

.list-container .new-object-title {
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1rem;
}